body, html {
  color: #eee;
  text-align: center;
  background-color: #1e1e1e;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  font-family: Monaco, Consolas, Courier New, Courier, monospace;
  font-style: normal;
  display: flex;
}

.boot-loader-container {
  width: 75%;
  max-width: 1200px;
  margin: auto;
}

.menu-container {
  box-sizing: border-box;
  border: 2px solid #526749;
  width: 100%;
  height: 60vh;
  margin: 20px 0;
  padding: 20px;
  display: inline-block;
}

h1 {
  margin-bottom: 20px;
  font-size: 1.5em;
  font-style: normal;
}

#menu {
  text-align: left;
  font-size: 1.1em;
}

#bootOptions {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.option {
  margin: 5px 0;
  padding: 3px;
}

.selected {
  background-color: #526749;
  font-weight: bold;
}

p {
  color: #fff;
  text-align: left;
  width: 80%;
  margin: 20px auto;
  padding: 0 20px;
  font-size: 1.1em;
}

a, a.visited, a.hover {
  color: #eee;
  text-decoration: none;
}

input[type="text"] {
  box-shadow: none;
  color: #eee;
  background-color: #0000;
  border: none;
  outline: none;
  font-family: Courier New, Courier, monospace;
  font-size: 1.1em;
}

#terminal-input {
  font-size: 1.1em;
}

#terminal {
  font-size: 1.1em;
  display: none;
}
/*# sourceMappingURL=index.4da80229.css.map */
