body, html {
    height: 100%;
    margin: 0;
    
    font-family: Monaco, Consolas, 'Courier New', Courier, monospace;
    font-style: normal;

    color: #eee;
    background-color: #1e1e1e;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.boot-loader-container {
    width: 75%; /* Almost full width */
    max-width: 1200px; /* Limits max width */
    margin: auto;
}

.menu-container {
    border: 2px solid #526749;
    padding: 20px;
    margin: 20px 0; /* Space above and below */
    width: 100%; /* Makes the menu container fill its parent */
    height: 60vh;
    box-sizing: border-box; /* Ensures padding doesn't add to the width */
    display: inline-block; /* Ensures the container fits the content */
}

h1 {
    margin-bottom: 20px;
    font-style: normal;
    font-size: 1.5em;
}

#menu {
    text-align: left;
    font-size: 1.1em;
}

#bootOptions {
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
}

.option {
    padding: 3px;
    margin: 5px 0;
}

.selected {
    font-weight: bold;
    background-color: #526749;
}

p {
    margin: 20px auto;
    padding: 0 20px; /* Ensures padding within the text block */
    width: 80%; /* Less than the menu box */
    color: #FFF;
    font-size: 1.1em;
    text-align: left;
}

a, a.visited, a.hover {
    color: #eee;
    text-decoration: none;
}

input[type="text"] {
    border: none; /* Removes the border */
    outline: none; /* Removes the outline */
    box-shadow: none; /* Removes the shadow */
    /* Additional styles to ensure the input is visually integrated */
    background-color: transparent; /* Makes the background transparent */
    font-family: 'Courier New', Courier, monospace;
    color: #eee; /* Makes the text white */
    font-size: 1.1em;
}

#terminal-input {
    font-size: 1.1em;
}

#terminal {
    display: none;
    font-size: 1.1em;
}